import React from 'react'
import styled from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'

import Container from 'components/shared/Container'
import { Heading } from 'components/shared/Typography'
import Button from 'components/shared/Button'

import { ReactComponent as NotFound404 } from 'assets/icons/404.svg'

const Section = styled.section`
  padding: 4rem 0;
  min-height: calc(100vh - 200px);
  ${({ theme }) => theme.media.md.max} {
    padding: 3rem 0;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`

const IconWrapper = styled.div`
  min-width: 200px;
  margin: 0 0.5rem;
  svg {
    width: 100%;
    height: 100%;
  }
  ${({ theme }) => theme.media.sm.min} {
    margin: 0 2rem;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  & > ${Button} {
    margin: 0 0.25rem;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.primary100};
    }
    ${({ theme }) => theme.media.md.min} {
      height: 60px;
    }
  }
  ${({ theme }) => theme.media.sm.max} {
    flex-direction: column;
    & > ${Button} {
      margin: 0.25rem 0;
      width: 100%;
      max-width: 400px;
    }
  }
`

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="404 - Artbud" />
      <main>
        <Section>
          <Container slim>
            <Header>
              <Heading size={360} sizeDiff={1.2} as="span" themecolor="primary">
                4
              </Heading>
              <IconWrapper>
                <NotFound404 />
              </IconWrapper>
              <Heading size={360} sizeDiff={1.2} as="span" themecolor="primary">
                4
              </Heading>
            </Header>
            <Heading
              as="div"
              size={30}
              weight={500}
              sizeDiff={0.6}
              align="center"
            >
              Podana strona nie istnieje, <em>podpowiemy</em> co możesz teraz
              zrobić.
            </Heading>
            <ButtonsWrapper>
              <Button width="240px" as={LocalizedLink} to="/">
                Strona główna
              </Button>
              <Button
                width="240px"
                outline
                as={LocalizedLink}
                to="/kontakt/biura-sprzedazy"
              >
                Kontakt
              </Button>
            </ButtonsWrapper>
          </Container>
        </Section>
      </main>
    </Layout>
  )
}

export default NotFoundPage
